import validate from "/home/forge/site.vespalazise.com/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/home/forge/site.vespalazise.com/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/forge/site.vespalazise.com/middleware/auth.ts"),
  guest: () => import("/home/forge/site.vespalazise.com/middleware/guest.ts"),
  unverified: () => import("/home/forge/site.vespalazise.com/middleware/unverified.ts"),
  verified: () => import("/home/forge/site.vespalazise.com/middleware/verified.ts")
}